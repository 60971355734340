/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-05",
    versionChannel: "nightly",
    buildDate: "2023-11-05T00:22:28.791Z",
    commitHash: "fca17a0061d30342ab06f4b0443212ca93d1c3f8",
};
